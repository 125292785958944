<template>
  <div class="container text-center">
    <div class="row">
      <div class="col">
        <div class="animated fadeIn">
          <svg
            class="info icon-large fa-question-circle"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style="background-color: transparent !important"
          >
            <path
              d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
            ></path>
          </svg>
        </div>
        <h1 class="animated fadeIn">Страница не найдена</h1>
        <div class="description-text animated fadeIn delay-1s">
          <p>Такой страницы не существует.</p>
          <p>Проверьте адрес и попробуйте снова или вернитесь назад.</p>
          <p class="mt-10">
            <v-btn href="/" depressed color="primary">
              Вернуться на главную
            </v-btn>
          </p>
          <section class="footer"><strong>Код ошибки:</strong> 404</section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFoundView',
}
</script>

<style scoped>
body {
  background-color: #f5f5f5;
  margin-top: 8%;
  color: #5d5d5d;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  text-align: center !important;
}

h1 {
  font-size: 2.45em;
  font-weight: 700;
  color: #5d5d5d;
  letter-spacing: -0.02em;
  margin-bottom: 30px;
  margin-top: 30px;
}

.container {
  width: 100%;
  margin-top: 10%;
  margin-right: auto;
  margin-left: auto;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.info {
  color: #5594cf;
  fill: #1c9aea;
}

.error {
  color: #c92127;
  fill: #c92127;
}

.warning {
  color: #ffcc33;
  fill: #ffcc33;
}

.success {
  color: #5aba47;
  fill: #5aba47;
}

.icon-large {
  height: 132px;
  width: 132px;
}

.description-text {
  color: #707070;
  letter-spacing: -0.01em;
  font-size: 1.25em;
  line-height: 20px;
}

.footer {
  margin-top: 40px;
  font-size: 0.7em;
}

.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
